@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  color: #262626;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  width: 100vw;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (min-width: 1024px) and (max-width: 1279px) {
  html {
    font-size: 14px
  }
}

@media (min-width: 1280px) and (max-width: 1400px) {
  html {
  font-size: 15px
}
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-datepicker-popper { z-index: 100 !important }