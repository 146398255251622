.email-table table {
  border-collapse: separate !important;
  text-indent: initial;
}
.email-table table td {
flex: 1;
}

.icon-text svg {
  transform: translateY(-2px)
}

.radio-shadow {
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.085))
}

.radio-shadow path {
 fill: rgba(255, 255, 255, 0.8);
stroke-width: 20px;
}

#order-confirmation td {
  vertical-align: top;
}