/* Styles for #content-main.admin */
#content-main.admin {
    padding-top: 200px;
}

@media (min-width: 768px) {
    #content-main.admin {
        padding-top: 248px;
    }
}

@media (min-width: 1024px) {
    #content-main.admin {
        padding-top: 220px;
    }
}

@media (min-width: 1280px) {
    #content-main.admin {
        padding-top: 248px;
    }
}

@media (min-width: 1536px) {
    #content-main.admin {
        padding-top: 244px;
    }
}

/* Styles for #content-main.user */
#content-main.user {
    padding-top: 120px;
}

@media (min-width: 768px) {
    #content-main.user {
        padding-top: 145px;
    }
}

@media (min-width: 1280px) {
    #content-main.user {
        padding-top: 153px;
    }
}

@media (min-width: 1536px) {
    #content-main.user {
        padding-top: 161px;
    }
}